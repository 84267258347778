<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/logo.svg')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              Stainte Augustine
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Se connecter a son espace de travail
          </p>
          <!--          <p class="mb-2">-->
          <!--            Please sign-in to your account and start the adventure-->
          <!--          </p>-->
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="UserLogin.email"
              outlined
              label="Email"
              placeholder="john@example.com"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="UserLogin.password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Mot de passe"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                label="Rester connecte"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox>

              <!-- forgot link -->
              <a
                href="javascript:void(0)"
                class="mt-1"
              >
                Mot de passe oubliée ?
              </a>
            </div>

            <v-btn
              block
              color="primary"
              class="mt-6"
              @click="redirect"
            >
              Connexion
            </v-btn>
          </v-form>
        </v-card-text>
<!--        <v-card-text class="d-flex align-center mt-2">-->
<!--          <v-divider></v-divider>-->
<!--          <span class="mx-5">or</span>-->
<!--          <v-divider></v-divider>-->
<!--        </v-card-text>-->

        <!-- social links -->
        <v-card-actions class="d-flex justify-center">
        </v-card-actions>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
// import { ref } from '@vue/composition-api'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import * as components from 'vuetify'
export default {
  data() {
    return {
      isPasswordVisible: false,
      UserLogin: {
        email: '',
        password: '',
      },
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },

  methods: {
    redirect() {
      this.$axios.post(this.$endpoint.LoginUser, this.UserLogin).then(rp => {
        localStorage.setItem('currentUser', JSON.stringify(rp.data.user))
        localStorage.setItem('accessToken', rp.data.access_token)
        this.$axios.post(this.$endpoint.CurrentUserInfos,{staff_id:rp.data.user.staff_id, staff_type: rp.data.user_type_id}).then(rp_two=>{
          localStorage.setItem('currentUserInfos',JSON.stringify(rp_two.data))
        })
        this.$router.push({ name: 'dashboard' })
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
